/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('offers.intro', {
    url: '/offers/intro',
    templateUrl: 'sections/offers-intro/templates/acp-offers-intro.ng.html',
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.offers-intro" */ 'sections/offers-intro').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
